<template>
    <div class="google-map new-updated-design google-map-design">
        <vue-snotify></vue-snotify>
        <div class="row">
    
            <div class="col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('heat-map') }}</h4>
                    </div>
                    
                    <div class="card-body new-card-body rl-body">
                        <div class="al-btn new-al-btn"> 
                            <b-button class="ml-5 variant-orange" size="sm" @click="openSiderBar">{{ $t('total-category-counts') }}
                            </b-button>
                            <b-button v-if="mapLoaded" class="ml-2 variant-orange" size="sm" @click="openFilters">{{ $t('filters-btn') }}</b-button>
                        </div>
                        <div class="google-map new-updated-design">
                            
                            <div class="map-container" id="mapContainer">
                            <!--    <button v-if="mapLoaded" class="filter-group" variant="info" size="sm" @click="openFilters">{{ $t('filters-btn') }}</button> -->
                            </div>
                            <div class="sidebar-counter">
                                <TotalCategoryCount  ref="sidebar" :totalSpots="totalSpots" user_type="admin"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="dashboard-spot-detail" :title="$t('spot-details')" size="md" :hide-footer="true">
            <div v-if="spotDetail.type == 'S'">
                <b-carousel id="carousel1" controls indicators background="#ababab" :interval="2000">
    
                    <!-- <b-carousel-slide v-for="images of spotDetailImages" :key="images.id">
                      <img slot="img" :src="serverUrl+images" alt="slide image"/>
                   </b-carousel-slide> -->
                    <b-carousel-slide v-for="images of spotDetailImages" :key="images.id">
                        <!-- <img slot="img" :src="serverUrl+images" alt="slide image"/> -->
                        <img slot="img" v-if="checkImage(images)" :src="serverUrl+images" alt="slide image" />
                        <audio class="mb-5 mt-5  text-justify" controls v-else :src="serverUrl+images"></audio>
    
                    </b-carousel-slide>
    
                    <!-- <div v-if="spotDetail.type == 'A'" >
                       <b-carousel-slide  v-for="images of spotDetailImages" :key="images.id" class="text-center">
                           <audio slot="img" controls :src="serverUrl+images" class="mb-5 mt-5  text-justify"></audio>
                       </b-carousel-slide>
                   </div> -->
                </b-carousel>
            </div>
            <div v-if="spotDetail.type == 'A'">
                <b-carousel id="carousel1" controls indicators background="#ababab" :interval="0">
    
                    <b-carousel-slide v-for="images of spotDetailImages" :key="images.id" class="text-center">
                        <audio slot="img" controls :src="serverUrl+images" class="mb-5 mt-5  text-justify"></audio>
                    </b-carousel-slide>
                </b-carousel>
            </div>
            <table class="table table-responsive  mt-4">
                <tr>
                    <td width="50%"><b>Spot</b></td>
                    <td width="50%"><span v-if="spotDetail.spot_type">{{spotDetail.spot_type.name}}</span></td>
                </tr>
                <tr>
                    <td width="50%"><b>Location</b></td>
                    <td width="50%"><span v-if="spotDetail.location">{{spotDetail.location.name}}</span></td>
                </tr>
                <tr>
                    <td width="50%"><b>Date</b></td>
                    <td width="50%"><span v-if="spotDetail.spot_date">{{this.formatDate(spotDetail.spot_date)}}</span>
                    </td>
                </tr>
                <tr>
                    <td width="50%"><b>Spotted By</b></td>
                    <td width="50%"><span v-if="spotDetail.spotter_user">{{spotDetail.spotter_user.user_name}}</span></td>
                </tr>
                <tr>
                    <td width="50%"><b>Detail</b></td>
                    <td width="50%"><span v-if="spotDetail.detail">{{spotDetail.detail}}</span></td>
                </tr>
    
                <tr v-for="spot_question of spotDetail.spot_questions" :key="spot_question.id">
                    <td width="50%"><b><span
                           v-if="spot_question.question">{{spot_question.question.question}}</span></b></td>
                    <td width="50%"><span v-if="spot_question.answer">{{spot_question.answer}}</span></td>
                </tr>
            </table>
        </b-modal>
        <FiltersModal @applyFilter="applyFilter" @resetMap="getClientAdminSpots" ref="filterComponent" user_type="admin"></FiltersModal>
    </div>
</template>

<script>
    import Vue from "vue";
    import mapboxgl from "mapbox-gl";
    import Snotify, {
        SnotifyPosition
    } from 'vue-snotify'
    import API from '@/api';
    import moment from 'moment'
    import FiltersModal from "../citizen/FiltersModal.vue";
    import TotalCategoryCount from '../client/TotalCategoryCounts';
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        props: ['countableQuestions', 'total_spots'],
        data() {
            return {
                accessToken: 'pk.eyJ1Ijoid2F0Y2hzcG90dGVyIiwiYSI6ImNsMmY0OTgxdzA3MW0zYm1jMmY5MGY5OG4ifQ.vEle7r52YhgPJ8D-MVlZ2A',
                map: [],
                spotData: [],
                features: [],
                mapFeatures: [],
                spotForm: {
                    requireLatLong: 1,
                    spot_type_id: '',
                    location_id: '',
                    detail: '',
                    spot_date: '',
                    latitude: '',
                    longitude: '',
                    questions: [],
                    client_url: window.location.href.split('#')[0],
                    client_id: JSON.parse(localStorage.getItem("client_id")),
                },
                adminMapStyle: '',
                default_map: '',
                default_map_zoom: '',
                default_latitude: '',
                default_longitude: '',
                appImage: null,
                spotDetailImages: [],
                spotDetail: [],
                mapLoaded: false,
                totalSpots: 0,
            };
        },
        components: {
            FiltersModal,
            TotalCategoryCount
        },
        created() {
            this.$EventBus.$on("reinitializeMap", (data) => {
                this.map.remove();
                this.getClientAdminSpots();
            });
        },
        mounted() {
            this.getAppSettings();
            this.getClientAdminSpots();
        },
        methods: {
            checkImage(image) {
                let found = image.search('.mp3');
                if (found === -1) {
                    return true;
                } else {
                    return false;
                }
            },
            openSiderBar() {
                this.$root.$emit("bv::toggle::collapse", "sidebar-no-header");
            },
            openSpotDetail(id) {
                const data = {
                    id: id
                }
                this.serverUrl = this.$server_url;
                API.getSpotDetail(
                    data,
                    data => {
                        this.spotDetail = data.data;
                        this.spotDetailImages = [];
                        if (this.spotDetail.image === null) {
                            this.spotDetailImages.push(this.defaultImage);
                        } else {
                            this.spotDetailImages.push(this.spotDetail.image);
                        }
    
                        if (this.spotDetail.image1 !== null) {
                            this.spotDetailImages.push(this.spotDetail.image1);
                        }
    
                        if (this.spotDetail.image2 !== null) {
                            this.spotDetailImages.push(this.spotDetail.image2);
                        }
                        this.$root.$emit("bv::hide::modal", "recent-spots");
                        this.$root.$emit("bv::show::modal", "dashboard-spot-detail");
                    },
                    err => {});
            },
            getClientAdminSpots() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),        
                }
                API.getClientAdminSpots(
                    data,
                    data => {
                        const spot_dImage = data.default_image;
                        this.totalSpots = data.total_spots;
    
                        data.data.forEach(data => {
                            let exists = this.mapFeatures.find(p => p.properties.id == data.id)
                            if (!exists) {
                                const d1 = {
                                    type: "Feature",
                                    properties: {
                                        id: data.id,
                                        title: (data.spot_type) ? data.spot_type.name : '',
                                        spot_id: data.spot_type_id,
                                        description: (data.location) ? data.location.name : '',
                                        spotted_by: (data.spotter_user) ? data.spotter_user.name : '',
                                        spot_date: this.formatDate(data.spot_date),
                                        iconUrl: (data.primary_image) ? this.$server_url + data.primary_image : spot_dImage,
                                    },
                                    geometry: {
                                        type: "Point",
                                        coordinates: [data.longitude, data.latitude]
                                    }
                                }
                                this.mapFeatures.push(d1);
                            }
                        })
    
                        // alert('working')
    
                        // if (navigator.geolocation) {
                        //     var self = this;
                        //     navigator.geolocation.getCurrentPosition(e => {
                        //         this.initMap([e.coords.longitude, e.coords.latitude])
                        //     }, function (error) {
                        //         if (error.code == error.PERMISSION_DENIED) {
                        //             // pop up dialog asking for location
                        //             self.initMap([self.default_longitude, self.default_latitude]);
                        //         }
                        //     })
                        // } else {
                        this.initMap([this.default_longitude, this.default_latitude]);
                        // }
                    },
                    err => {}
                )
            },
            initMap(lngLat) {
                this.mapLoaded = false;
                mapboxgl.accessToken = this.accessToken;
                this.map = new mapboxgl.Map({
                    container: 'mapContainer',
                    style: 'mapbox://styles/mapbox/satellite-v9',
                    center: lngLat,
                    zoom: this.default_map_zoom,
                    // projection: 'globe'
                    attributionControl: false
                });
    
                this.map.on('style.load', () => {
                    this.map.setFog({});
                });
    
                this.map.on('load', () => {
    
                    this.data2 = {
                        "type": "FeatureCollection",
                        "crs": {
                            "type": "name",
                            "properties": {
                                "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
                            }
                        },
                        "features": this.mapFeatures
                    }
    
                    // Add a geojson point source.
                    // Heatmap layers also work with a vector tile source.
                    this.map.addSource('earthquakes', {
                        'type': 'geojson',
                        'data': this.data2
                    });
    
                    const nav = new mapboxgl.NavigationControl();
                    this.map.addControl(nav, "top-right");
    
                    this.map.addLayer({
                            'id': 'earthquakes-heat',
                            'type': 'heatmap',
                            'source': 'earthquakes',
                            'maxzoom': 9,
                            'paint': {
                                // Increase the heatmap weight based on frequency and property magnitude
                                'heatmap-weight': [
                                    'interpolate', ['linear'],
                                    ['get', 'mag'],
                                    0,
                                    0,
                                    6,
                                    1
                                ],
                                // Increase the heatmap color weight weight by zoom level
                                // heatmap-intensity is a multiplier on top of heatmap-weight
                                'heatmap-intensity': [
                                    'interpolate', ['linear'],
                                    ['zoom'],
                                    0,
                                    1,
                                    9,
                                    3
                                ],
                                // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                                // Begin color ramp at 0-stop with a 0-transparancy color
                                // to create a blur-like effect.
                                'heatmap-color': [
                                    'interpolate', ['linear'],
                                    ['heatmap-density'],
                                    0,
                                    'rgba(33,102,172,0)',
                                    0.2,
                                    'rgb(103,169,207)',
                                    0.4,
                                    'rgb(209,229,240)',
                                    0.6,
                                    'rgb(253,219,199)',
                                    0.8,
                                    'rgb(239,138,98)',
                                    1,
                                    'rgb(178,24,43)'
                                ],
                                // Adjust the heatmap radius by zoom level
                                'heatmap-radius': [
                                    'interpolate', ['linear'],
                                    ['zoom'],
                                    0,
                                    2,
                                    9,
                                    20
                                ],
                                // Transition from heatmap to circle layer by zoom level
                                'heatmap-opacity': [
                                    'interpolate', ['linear'],
                                    ['zoom'],
                                    7,
                                    1,
                                    9,
                                    0
                                ]
                            }
                        },
                        // 'waterway-label'
                    );
    
                    this.map.addLayer({
                            'id': 'earthquakes-point',
                            'type': 'circle',
                            'source': 'earthquakes',
                            'minzoom': 7,
                            'paint': {
                                // Size circle radius by earthquake magnitude and zoom level
                                'circle-radius': [
                                    'interpolate', ['linear'],
                                    ['zoom'],
                                    7, ['interpolate', ['linear'],
                                        ['get', 'mag'], 1, 1, 6, 4
                                    ],
                                    16, ['interpolate', ['linear'],
                                        ['get', 'mag'], 1, 5, 6, 50
                                    ]
                                ],
                                // Color circle by earthquake magnitude
                                'circle-color': [
                                    'interpolate', ['linear'],
                                    ['get', 'mag'],
                                    1,
                                    'rgba(33,102,172,0)',
                                    2,
                                    'rgb(103,169,207)',
                                    3,
                                    'rgb(209,229,240)',
                                    4,
                                    'rgb(253,219,199)',
                                    5,
                                    'rgb(239,138,98)',
                                    6,
                                    'rgb(178,24,43)'
                                ],
                                'circle-stroke-color': 'white',
                                'circle-stroke-width': 1,
                                // Transition from heatmap to circle layer by zoom level
                                'circle-opacity': [
                                    'interpolate', ['linear'],
                                    ['zoom'],
                                    7,
                                    0,
                                    8,
                                    1
                                ]
                            }
                        },
                        // 'waterway-label'
                    );
                    this.mapLoaded = true;
                    // this.map.on('click', 'earthquakes-point', (e) => {
                    //     alert(e)
                    // })    
                });
                const logo = document.querySelector('.mapboxgl-ctrl-logo');
                if (logo) {
                    logo.remove();
                }
            },
            openFilters() {
                this.$root.$emit("bv::show::modal", "modal-map-filters");
                // this.$refs.filterComponent.getFiltersData();
            },
            applyFilter(params) {
                let data = {
                    spot_id: params.spotId,
                    date_from: params.dateFrom,
                    date_to: params.dateTo,
                    questions: params.questions,
                    categories: params.categories,
                    locations: params.locations,
                    surveys: params.surveys,
                    spotTypes: params.spotTypes,
                    selectedQuestions: params.selectedQuestions,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    countRangeFilter: params.countRangeFilter,
                    countRangeValues: params.countRangeValues,
                }
                console.log('filter data', data);
                API.getSpotsByAdminFilters(data,
                    data => {
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                            this.data2.features = [];
                            this.mapFeatures = [];
                            this.totalSpots = data.total_spots;
                            this.$refs.sidebar.assignQuestions(data.countableQuestions);
                            this.$refs.filterComponent.StopFilterLoading();
                            this.$refs.filterComponent.StopResetLoading();
                            this.$refs.filterComponent.changeSliderValue(data.range);
                            const spot_dImage = data.default_image;
                            data.data.forEach(data => {
                                console.log('spot', data);
                                let exists = this.mapFeatures.find(p => p.properties.id == data.id)
                                if (!exists) {
                                    const d1 = {
                                        type: "Feature",
                                        properties: {
                                            id: data.id,
                                            title: (data.spot_type) ? data.spot_type.name : '',
                                            // spot_id: data.spot_type_id,
                                            spot_id: data.spot_id,
                                            description: (data.location) ? data.location.name : '',
                                            spotted_by: (data.spotter_user) ? data.spotter_user.name : '',
                                            spotted_username: data.spotter_user.user_name,
                                            spot_date: this.formatDate(data.spot_date),
                                            iconUrl: (data.primary_image) ? this.$server_url + data.primary_image : spot_dImage,
                                        },
                                        geometry: {
                                            type: "Point",
                                            coordinates: [data.longitude, data.latitude]
                                        }
                                    }
                                    this.mapFeatures.push(d1);
                                }
                            })
                            this.data2 = {
                                "type": "FeatureCollection",
                                "crs": {
                                    "type": "name",
                                    "properties": {
                                        "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
                                    }
                                },
                                "features": this.mapFeatures
                            }
                            this.map.getSource("earthquakes").setData(this.data2);
                            console.log('applyFilterresponse', data)
                        } else if (data.status === 500) {
                            this.$snotify.error(data.message);
                            this.$refs.filterComponent.StopFilterLoading();
                            this.$refs.filterComponent.StopResetLoading();
                        }
                    },
                    err => {
                        console.log(err);
                        this.$refs.filterComponent.StopFilterLoading();
                        this.$refs.filterComponent.StopResetLoading();
                    }
                );
            },
            formatDate(date) {
                return moment(String(date)).format('DD-MM-YYYY')
            },
    
            getAppSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAppSettings(
                    data,
                    data => {
                        this.appImage = this.$server_url + data.data.stock_image;
                        // this.adminMapStyle = data.data.default_map;
                        // this.default_map = data.data.default_map;
                        this.default_map_zoom = data.data.default_map_zoom;
                        this.default_latitude = (data.data.latitude) ? data.data.latitude : 39.452717;
                        this.default_longitude = (data.data.longitude) ? data.data.longitude : -123.813866;
    
                    },
                    err => {}
                )
            },
        }
    };
</script>

<style scoped>
    .basemap {
        width: 100%;
        height: 100%;
    }
    
    .mapboxgl-canvas {
        width: 1200px;
        height: 689.15px;
        position: static !important;
    }
    
    .mapboxgl-canvas-container {
        position: static !important;
        width: 1200px !important;
    }
    
    #menu {
        position: absolute;
        background: #efefef;
        padding: 10px;
        font-family: 'Open Sans', sans-serif;
        z-index: 1;
    }
    .google-map-design {
        min-height: calc(100vh - 184px);
        height: 100%;
    }
    .google-map-design #mapContainer {
        height: calc(100vh - 285px);
    }
    .rl-body {
        position: relative;
    }
    .al-btn {
        position: absolute;
        left: -10px;
        z-index: 5;
        top: 30px;
    }
    .al-btn button {
        color:#fff !important;
    }
    .al-btn button:hover {
        color:#fff !important;
    }
    .variant-orange {
        color: #fff !important;
        background-color: #fa6400;
        border-color: #fa6400;
    }
    @media (max-width: 375px) {
        .row .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
        .new-al-btn {
            left: 0px;
        }
        .new-al-btn button:nth-child(1) {
            margin-left: 28px !important;
            margin-right: 0px !important;
        }
        .new-al-btn button:nth-child(2) {
            margin-left: 5px !important;
        }
    }
</style>
